import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonTourService } from '@app/shared/services/commonTour/common-tour-service';
import { TranslateModule } from '@ngx-translate/core';
@Component({
  selector: 'app-popup',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './popup.component.html',
  styleUrl: './popup.component.scss',
})
export class PopupComponent {
  constructor(
    private commontourservice: CommonTourService,
    private dialogref: MatDialogRef<PopupComponent>
  ) {}

  elem = document.documentElement;

  explore() {
    this.elem.requestFullscreen();
    this.commontourservice.startTour();
    this.dialogref.close(false);
  }
  skipForNow() {
    return this.dialogref.close(false);
  }
  dontShowAgain() {
    const checkbox = document.getElementById(
      'dontShowAgain'
    ) as HTMLInputElement;

    sessionStorage.setItem('isOpen', JSON.stringify(checkbox.checked));
    this.dialogref.close();
  }
}
